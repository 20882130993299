@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mapBoxPopup {
  /* width: 400px;
  height: 300px; */
  background-color: #FAFAD2;
}

.massive-shadow {
  box-shadow: 0px 4px 14px 0px rgb(105, 105, 105);
}

.large-shadow {
  box-shadow: 3px 3px 0px 0px rgb(63, 63, 63);
}

.medium-shadow {
  box-shadow: 2px 2px 0px 0px rgb(63, 63, 63);
}

.large-bold-shadow {
  box-shadow: 3px 4px 0px 0px rgba(65, 65, 65, 0.902);
}

.small-bold-shadow {
  box-shadow: 0px 2px 2px 0px rgb(65, 65, 65, .6);
}

.small-bold-shadow-cars {
  box-shadow: 0px 2px 0px 0px rgb(65, 65, 65, .6);
}

.smaller-bold-shadow {
  box-shadow: 1px 1px 0px 0px rgba(53, 53, 53, 0.6);
}

.smaller-bold-shadow-splide {
  box-shadow: 0px 1px 0px 0px rgba(53, 53, 53, 0.6);
}

.dropdown-large-shadow {
  box-shadow: 6px 15px 30px 5px rgb(65, 65, 65, .4);
}

.chargerSelectorDropdown {
  box-shadow: 0px 18px 30px 5px rgb(65, 65, 65, .4);
}

.settingsShadow {
  --tw-shadow: -40px 20px 30px -0px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: -20px 0 25px 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dashboardScrollBar::-webkit-scrollbar {
  width: 10px;
}

.dashboardScrollBar::-webkit-scrollbar-track {
  background: #CCCCCC;
}

.dashboardScrollBar::-webkit-scrollbar-thumb {
  background: #9c9c9c;
}

.dashboardScrollBarGraphRounded::-webkit-scrollbar {
  width: 10px;
}

.dashboardScrollBarGraphRounded::-webkit-scrollbar-track {
  background: #CCCCCC;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 0px;
}

.dashboardScrollBarGraphRounded::-webkit-scrollbar-thumb {
  background: #9c9c9c;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 0px;
}

.parkingClusterDropdownScrollbar::-webkit-scrollbar {
  width: 10px;
}

.parkingClusterDropdownScrollbar::-webkit-scrollbar-track {
  background: #CCCCCC;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.parkingClusterDropdownScrollbar::-webkit-scrollbar-thumb {
  background: #9c9c9c;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.policyFormScrollBar::-webkit-scrollbar {
  width: 10px;
}

.policyFormScrollBar::-webkit-scrollbar-track {
  background: #CCCCCC;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.policyFormScrollBar::-webkit-scrollbar-thumb {
  background: #9c9c9c;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.policyPageMapSettingsScrollBar::-webkit-scrollbar {
  width: 10px;
}

.policyPageMapSettingsScrollBar::-webkit-scrollbar-track {
  background: #a0a0a1;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.policyPageMapSettingsScrollBar::-webkit-scrollbar-thumb {
  background: #9c9c9c;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.filterByUserPassManagerScrollBar::-webkit-scrollbar {
  width: 10px;
}

.filterByUserPassManagerScrollBar::-webkit-scrollbar-track {
  background: #CCCCCC;
  border-bottom-right-radius: 6px;
}

.filterByUserPassManagerScrollBar::-webkit-scrollbar-thumb {
  background: #9c9c9c;
  border-bottom-right-radius: 6px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(-5%);
  }
  to {
    transform: translateX(100%);
  }
}

.slideIn {
  animation: slideInFromRight 0.15s ease-out forwards;
}

.slideOut {
  animation: slideOutToRight 0.15s ease-out forwards;
}

@keyframes pulseBackground {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.pulsating-background {
  background: linear-gradient(45deg, #406325, #799166, #799166, #406325);
  background-size: 400% 400%;
  animation: pulseBackground 4s ease infinite;
}

.lds-ring-loading {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
}
.lds-ring-loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 4px;
  border: 2px solid #000000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffffff transparent transparent transparent;
}
.lds-ring-loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-loading div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}